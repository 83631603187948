import React, { useState } from "react";
import './AgentCard.css';
import Call from '../images/icons/call.svg';
import Send from '../images/icons/sendMessage.svg';
import Close from '../images/icons/close.svg'; // Ensure you have a close icon
import ContactForm from '../Components/ContactForm';

function AgentCard(props) {
    const [isContactFormVisible, setContactFormVisible] = useState(false);

    const handleContactClick = (e) => {
        e.preventDefault();
        setContactFormVisible(true);
    };

    const handleCloseFormClick = (e) => {
        e.preventDefault();
        setContactFormVisible(false);
    };

    const handleClose = () => {
        if (props.onClose) {
            props.onClose(); // Call the onClose function passed from parent
        }
    };

    return (
        <div className={props.className}>
            <div className='content'>
                <button className='closeButtonIco' onClick={handleClose}>
                    <img src={Close} alt='Close' className='closeGl'/>
                </button>
                <div className='flexRow'>
                    <div>
                        <img src={props.src} className='agentPic' alt='Agent'/>
                    </div>
                    <div>
                        <h2 className='agentName'>{`${props.agent_surname}  ${props.agent_name}`}</h2>
                        <p className='agentTitle'>Broker Imobiliar</p>
                    </div>
                </div>
                <div className='flexRow'>
                    <div className='secondaryButton linkPointer flexItem'>
                        <img src={Call} alt='Call'/>
                        <a className='unstyledLink' href={`tel:${props.phone_no}`}>{props.phone_no}</a>
                    </div>
                    <div className='secondaryButton linkPointer flexItem'>
                        <img src={Send} alt='Send Message'/>
                        <a className='unstyledLink' href="#"
                           onClick={handleContactClick}>Lasă un mesaj</a>
                    </div>
                    {isContactFormVisible && (
                        <div className='secondaryButton linkPointer flexItem'>
                            {/* Ghost button to close the form */}
                            <a href="#" onClick={handleCloseFormClick} className="unstyledLink ghost-button">
                                Inchide Formular
                            </a>
                        </div>
                    )}
                </div>
                {isContactFormVisible && (
                    <ContactForm propertyId={props.propertyId}/>
                )}
            </div>
        </div>
    );
}

export default AgentCard;
