import { useWishlist } from "../Context/WishlistContext"; // Import wishlist context
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios"; // Import Axios
import Spinner from "../Components/Spinner"; // Import Spinner for loading state
import Property from "../Components/Property"; // Import Property component
import './PagesCss/wishlistPage.css'; // Create a new CSS file for styling if needed

function WishlistPage() {
    const { wishlist } = useWishlist(); // Get the wishlist from the context
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [images, setImages] = useState([]); // Store images separately

    useEffect(() => {
        const fetchProperties = async () => {
            setLoading(true);
            setError(null);

            try {
                // Fetch both properties and images
                const [propertiesResponse, imagesResponse] = await Promise.all([
                    axios.get('https://project1.lbi.studio/api/api/Properties.php?allProperties'),
                    axios.get('https://project1.lbi.studio/api/api/Images.php?allImages')
                ]);

                // Check for properties response
                if (!propertiesResponse.data || !propertiesResponse.data.properties) {
                    throw new Error('No properties found in response');
                }

                // Check for images response
                if (!imagesResponse.data) {
                    throw new Error('No images found in response');
                }

                // Set images to state
                setImages(imagesResponse.data);

                // Filter properties based on the IDs in the wishlist
                const propertiesData = propertiesResponse.data.properties.filter(item =>
                    wishlist.includes(item.property_id) // Check if the property ID is in the wishlist
                );

                // Combine properties with their images
                const propertiesWithImages = propertiesData.map(property => {
                    const mainImage = imagesResponse.data.find(img => img.property_id === property.property_id);
                    return {
                        ...property,
                        image: mainImage // Attach image data to the property
                    };
                });

                setProperties(propertiesWithImages);
            } catch (err) {
                setError('Failed to fetch properties. Please try again later.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        if (wishlist.length > 0) {
            fetchProperties();
        } else {
            setLoading(false);
        }
    }, [wishlist]);

    useEffect(() => {
        // Reset properties if the wishlist is empty
        if (wishlist.length === 0) {
            setProperties([]);
        }
    }, [wishlist]);

    if (loading) {
        return <div className="wishlist-container"><Spinner /></div>; // Show spinner while loading
    }

    if (error) {
        return (
            <div className="wishlist-container">
                <h2>{error}</h2>
                <Link to="/proprietati" className="explore-btn">Vezi Proprietati</Link>
            </div>
        );
    }

    if (!properties || properties.length === 0) {
        return (
            <div className="wishlist-container">
                <h2>Wishlist-ul Tau</h2>
                <p>Vezi proprietatile disponibile si adauga-le la wishlist!</p>
                <Link to="/proprietati" className="explore-btn">Vezi Proprietati</Link>
            </div>
        );
    }

    let apartamente = null;
    if (images.length > 0 && properties.length > 0) {
        // Sort the images array by main_image in ascending order
        const sortedImages = images.sort((a, b) => a.main_image.localeCompare(b.main_image));

        apartamente = properties.map((item) => {
            // Find the first image after sorting for the specific property
            const mainImage = sortedImages.find((imgEl) => imgEl.property_id === item.property_id);

            return (
                <Property
                    key={item.property_id}
                    image={mainImage}
                    price={parseFloat(item.price).toLocaleString('de-DE')}
                    location={item.property_name}
                    bedrooms={item.rooms}
                    bathrooms={item.baths}
                    sqrM={item.use_sqm}
                    propertyId={item.property_id} // Pass property_id
                    propertyName={item.property_name} // Pass property name
                    isInWishlist={true} // It is in the wishlist
                    toggleWishlist={() => {}} // No toggle needed here, or provide a no-op function
                />
            );
        });
    }

    return (
        <div className="wishlist-container">
            <h2>Your Wishlist</h2>
            <div className="wishlist-items grid">
                {apartamente}
            </div>
        </div>
    );
}

export default WishlistPage;
