import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import RealEstateListPage from "./Pages/RealEstateList";
import RootLayout from "./Components/Root";
import RealEstateListPage2 from "./Pages/RealEstateList2";
import ProductDetail from "./Pages/ProductDetail";
import ContactPage from "./Pages/ContactPage";
import Projects from "./Pages/Projects";
import WishlistPage from "./Pages/WishlistPage";
import TermsAndConditions from "./Pages/TermsAndConditions";

const router = createBrowserRouter([
    { path: '/', element: <RootLayout />,
        children: [
            { path: '/', element: <HomePage /> },
            { path: '/proprietati', element: <RealEstateListPage /> },
            { path: '/proprietati/:type', element: <RealEstateListPage /> },
            { path: '/proprietatiTest', element: <RealEstateListPage2 /> },
            { path: '/inchiriaza', element: <RealEstateListPage chirie /> },
            { path: '/inchiriaza/:type', element: <RealEstateListPage chirie /> },
            { path: '/product/:productId', element: <ProductDetail /> },
            { path: '/contact', element: <ContactPage /> },
            { path: '/proiecte', element: <Projects /> },
            { path: '/wishlist', element: <WishlistPage /> },
            { path: '/termeni-conditii', element: <TermsAndConditions /> },
        ],
    }
]);

function App() {
    return (
        <RouterProvider router={router} />
    );
}

export default App;
