import React from 'react';
import './PagesCss/ContactPage.css';  // Create a new CSS file for styling

function ContactPage() {
    return (
        <div className="contact-page">
            <h1>Contact Us</h1>
            <p>Dacă aveți întrebări, nu ezitați să ne contactați. Suntem bucuroși să vă ajutăm!</p>

            <div className="contact-details">
                <div className="contact-info">
                    <h2>Datele Noastre de Contact</h2>
                    <p><strong>Phone:</strong> 0785 022 279</p>
                    <p><strong>Email:</strong> <a className='noStyleLink'
                                                  href='mailto:contact@atumimobiliare.com'>contact@atumimobiliare.com</a>
                    </p>
                    <p><strong>Address:</strong> Strada Rahovei, Nr. 10, Sibiu</p>
                    <iframe className='contact-map'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2744.2292251580648!2d24.159691092884525!3d45.780463274587234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474c6721556b3bbd%3A0x359dddf7eb9773ef!2sAtum%20Imobiliare!5e0!3m2!1sen!2sro!4v1727205718964!5m2!1sen!2sro"
                        width="100%" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <form className="contact-form">
                    <h2>Lasa-ne un mesaj</h2>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" placeholder="Introdu Numele tau" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="Tel">Tel</label>
                        <input type="tel" id="tel" name="email" placeholder="Numraul tau de telefon" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" placeholder="Email" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" placeholder="Lasa-ne un mesaj" rows="5" required></textarea>
                    </div>
                    <button className='mainBtn deepBlue' type="submit">Send Message</button>
                </form>
            </div>
        </div>
    );
}

export default ContactPage;
