import { Outlet, useLocation } from "react-router-dom";
import { WishlistProvider } from "../Context/WishlistContext";
import MainNavigation from "./MainNavigation";
import Footer from "./footer";
import { useEffect } from "react";
import { PhoneProvider } from "../Context/PhoneContext";

function RootLayout() {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (hash) {
            setTimeout(() => {
                const element = document.getElementById(hash.replace("#", ""));
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 100);
        } else {
            window.scrollTo(0, 0);
        }
    }, [pathname, hash]);

    return (
        <PhoneProvider>
            <WishlistProvider>
                <div>
                    <MainNavigation />
                    <Outlet />
                    <Footer />
                </div>
            </WishlistProvider>
        </PhoneProvider>
    );
}

export default RootLayout;
