import React from "react";
import './RealEstateTable.css';

function RealEstateTable(props) {
    const {
        property_id, year_constr, use_sqm, total_sqm, rooms, baths, balconies, floor, total_floors, orientation, parking_spaces
    } = props;

    // Create an array of property objects
    const properties = [
        { label: "ID Proprietate", value: property_id },
        { label: "Anul construcției", value: year_constr },
        { label: "Suprafață Utilă", value: use_sqm ? `${use_sqm} mp` : null },
        { label: "Total Suprafață", value: total_sqm ? `${total_sqm} mp` : null },
        { label: "Camere", value: rooms },
        { label: "Băi", value: baths },
        { label: "Balcoane", value: balconies },
        { label: "Etaj", value: floor },
        { label: "Număr Etaje", value: total_floors ? `${total_floors} etaje` : null },
        { label: "Locuri de Parcare", value: parking_spaces },
        { label: "Orientare", value: orientation }
    ];

    // Filter out any null or undefined values
    const validProperties = properties.filter(property => property.value);

    // Split properties into two columns, ensuring both columns are roughly equal
    const midIndex = Math.ceil(validProperties.length / 2); // Calculate middle index
    const column1 = validProperties.slice(0, midIndex); // First half of properties
    const column2 = validProperties.slice(midIndex); // Second half of properties

    return (
        <div className="property-details">
            <h3>Detalii Proprietate</h3>
            <div className="property-grid">
                {/* First Column */}
                <div className="property-column">
                    <table className="property-table">
                        <tbody>
                        {column1.map(
                            (item, index) => (
                                <tr key={index}>
                                    <td className="property-label">{item.label}</td>
                                    <td className="property-value">{item.value}</td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </table>
                </div>

                {/* Second Column */}
                <div className="property-column">
                    <table className="property-table">
                        <tbody>
                        {column2.map(
                            (item, index) => (
                                <tr key={index}>
                                    <td className="property-label">{item.label}</td>
                                    <td className="property-value">{item.value}</td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default RealEstateTable;
