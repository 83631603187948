// src/pages/Projects.js
import React from 'react';
import './PagesCss/Projects.css'; // Import the updated CSS file
import Project1 from '../images/Project1/Project_01.jpeg';
import Project2 from '../images/Project2/Project_01.jpeg';

function Projects() {
    const projectData = [
        {
            title: 'Proiect 1',
            description: 'Proiect de case single la cheie pe un singur nivel cu 1000 mp teren',
            image: Project1,
        },
        {
            title: 'Proiect 2',
            description: 'Proiect de case single la cheie cu 1000 mp teren',
            image: Project2,
        },
        // Add more projects as needed
    ];

    return (
        <div className="projects-container">
            <h1 className='ourProjects'>Proiectele noastre</h1>
            <p>Prin aceste proiecte „la cheie”, dorim să simplificăm procesul de achiziție pentru clienți și să oferim mai mult decât o simplă casă: oferim o experiență rezidențială completă, creată pentru confortul, siguranța și liniștea familiei tale.</p>
            <div className="projects-grid">
                {projectData.map((project, index) => (
                    <div key={index} className="project-card">
                        <img src={project.image} alt={project.title} className="project-image" />
                        <h2 className="project-title">{project.title}</h2>
                        <p className="project-description">{project.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Projects;
