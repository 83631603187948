import './footer.css';
import logo from '../images/logos/logo.svg';
import facebook from '../images/icons/smi/facebookF.svg';
import instagram from '../images/icons/smi/igFontAwesome.svg';
import youtube from '../images/icons/smi/youtubeWhite.svg';
import {Link} from "react-router-dom";

function Footer() {
    return (
        <section className='footer'>
            <div className='propertyFlex'><img className='logoFooter' src={logo} alt='Atum Imobiliare'/></div>
            <div className='propertyFlex'>
                <div className='propertyFlex containerWidthFooter'>
                    <div className='footerItemsFlex'>
                        <h3 className='white'>Agentie</h3>
                        <ul>
                            <li><Link className='footerNav' to='/proprietati'>Cumpara</Link></li>
                            <li><Link className='footerNav' to='/contact'>Vinde</Link></li>
                            <li><Link className='footerNav' to='/inchiriaza'>Inchiriaza</Link></li>
                        </ul>
                    </div>
                    <div className='footerItemsFlex'>
                        <h3 className='white'>Despre noi</h3>
                        <ul>
                            <li>Despre Noi</li>
                            <li>Echipa</li>
                            <li><Link className='footerNav' to='/contact'>Contact</Link></li>
                        </ul>
                    </div>
                    <div className='footerItemsFlex'>
                        <h3 className='white'>Legal</h3>
                        <ul>
                            <li><Link className='footerNav' to='/termeni-conditii'>Termeni si Conditii</Link></li>
                            <li>GDPR</li>
                            <li>ANPC</li>
                        </ul>
                    </div>
                    <div className='footerItemsFlex'>
                        <h3 className='white'>Follow us</h3>
                        <div className='propertyFlexLeft paddingSocial'>
                            <a target='_blank' href='https://www.facebook.com/AtumImobiliare/'>
                                <img src={facebook} alt='Facebook' height='16px'/>
                            </a>
                            <a target='_blank' href='https://www.instagram.com/atum.imobiliare/'>
                                <img src={instagram} alt='Instagram' height='16px' className='marginSocial'/>
                            </a>
                            <a target='_blank' href='https://www.youtube.com/@atum.imobiliare'>
                                <img src={youtube} alt='Youtube' height='16px' className='marginSocial'/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='footerItemsFlex'>
                    <h3 className='white'>ATUM IMOBILIARE MR</h3>
                    <ul>
                        <li>Adresa: Strada Rahovei, Nr. 10, Sibiu</li>
                        <li>0785 022 279</li>
                        <li>contact@atumimobiliare.com</li>
                    </ul>
                </div>
            </div>
            <p className='white'>
                ATUM IMOBILIARE MR 2024&#169;
            </p>
        </section>
    )
}

export default Footer;