import React, { useEffect, useState, useRef } from "react";
import HomeHeroImg from "../Components/HomeHeroImg";
import Heading2 from "../Components/Heading2";
import CategoryCard from "../Components/CategoryCard";
import HomeSeparator from "../Components/HomeSeparator";
import Property from "../Components/Property";
import { Link } from "react-router-dom";
import axios from "axios";
import {useWishlist} from "../Context/WishlistContext";
import MainNavigation from "../Components/MainNavigation";

// Images imports
import ApartamenteImg from "../images/apartamente.png";
import AnsambluriRez from "../images/ansambluriRez.png";
import Case from '../images/case.png';
import Terenuri from '../images/terenuri.png';
import Spatii from '../images/spatii.png';
import Commercial from '../images/commercialNew.png';
import Industrial from '../images/industrialNew.png';
import Hotel from '../images/hotelNew.png';

function HomePage() {
    const [data, setData] = useState([]);
    const [image, setImage] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('cumpara'); // State variable to track the selected category
    const [isMounted, setIsMounted] = useState(false); // New state to track if the component has mounted
    const categoriesRef = useRef(null);
    const { isInWishlist, toggleWishlist } = useWishlist();

    useEffect(() => {
        fetchData();
    }, []);



    const fetchData = async () => {
        try {
            const response = await axios.get('https://project1.lbi.studio/api/api/Properties.php?allProperties'); //TODO MODIFY PATH FOR LIVE
            const imageResponse = await axios.get('https://project1.lbi.studio/api/api/Images.php?allImages'); //TODO modify the path for live build
            setData(response.data.properties);
            setImage(imageResponse.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        setIsMounted(true);
    }, []);

    const scrollToCategories = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        if (isMounted && categoriesRef.current) {
            const elementPosition = categoriesRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - 150;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    }, [selectedCategory, isMounted]);




    const numarApartamente = data.filter(item => (item.property_type_id === '1' || item.property_type_id === '7') && item.trans_id === '16');
    const numarApartamenteChirie = data.filter(item => item.property_type_id === '1' && item.trans_id === '14');
    const numarCase = data.filter(item => item.property_type_id === '2' && item.trans_id === '16');
    const numarCaseChirie = data.filter(item => item.property_type_id === '2' && item.trans_id === '14');
    const numarSpatiiComerciale = data.filter(item => item.property_type_id === '3' && item.trans_id === '16');
    const numarSpatiiComercialeChirie = data.filter(item => item.property_type_id === '3' && item.trans_id === '14');
    const numarTerenuri = data.filter(item => item.property_type_id === '4' && item.trans_id === '16');
    const numarTerenuriChirie = data.filter(item => item.property_type_id === '4' && item.trans_id === '14');
    const numarSpatiiIndustriale = data.filter(item => item.property_type_id === '5' && item.trans_id === '16');
    const numarSpatiiIndustrialeChirie = data.filter(item => item.property_type_id === '5' && item.trans_id === '14');
    const numarSpatiiBirouri = data.filter(item => item.property_type_id === '6' && item.trans_id === '16');
    const numarSpatiiBirouriChirie = data.filter(item => item.property_type_id === '6' && item.trans_id === '14');
    const numarHoteluri = data.filter(item => item.property_type_id === '8' && item.trans_id === '16');
    const numarHoteluriChirie = data.filter(item => item.property_type_id === '8' && item.trans_id === '14');

    let dataApartamente = data
        .filter(item => item.trans_id === '16')  // Filter for "apartamente" (buy)
        .sort((a, b) => new Date(b.last_review) - new Date(a.last_review)); // Sort by last_review date

    const limitedApartamente = dataApartamente.slice(0, 8); // Get the most recent 8 offers
    console.log(limitedApartamente);


    let apartamente = null;

    if (image.length > 0 && limitedApartamente.length > 0) {
        // Sort the images array by main_image in ascending order
        const sortedImages = image.sort((a, b) => a.main_image.localeCompare(b.main_image));

        apartamente = limitedApartamente.map((item) => {
            // Find the first image after sorting for the specific property
            const mainImage = sortedImages.find((imgEl) => imgEl.property_id === item.property_id);

            return (

                <Property
                    key={item.property_id}
                    image={mainImage}
                    price={parseFloat(item.price).toLocaleString('de-DE')}
                    location={item.property_name}
                    bedrooms={item.rooms}
                    bathrooms={item.baths}
                    sqrM={item.use_sqm}
                    propertyId={item.property_id} // Pass property_id
                    propertyName={item.property_name} // Pass property name
                    isInWishlist={isInWishlist(item.property_id)} // Check if the property is in wishlist
                    toggleWishlist={toggleWishlist} // Pass the toggleWishlist function
                />
            )
        });
    }

    return (
        <div className="App">
            {scrollToCategories && <MainNavigation scrollToCategories={scrollToCategories} />}

            <section className="App-header">
                <HomeHeroImg scrollToCategories={scrollToCategories} />
            </section>
            <div ref={categoriesRef} id='selectedCategories'>
                <Heading2 text='Cauta dupa Categorii' />
            </div>
            <div className='categoryCard'>
                <div className='center'>
                    <ul>
                        <li>
                            <button
                                className={selectedCategory === 'cumpara' ? 'mainNavLinksActive' : 'mainNavLinks'}
                                onClick={() => setSelectedCategory('cumpara')}
                            >
                                Cumpara
                            </button>
                        </li>
                        <li>
                            <button
                                className={selectedCategory === 'inchiriaza' ? 'mainNavLinksActive' : 'mainNavLinks'}
                                onClick={() => setSelectedCategory('inchiriaza')}
                            >
                                Inchiriaza
                            </button>
                        </li>
                    </ul>
                </div>

                    {selectedCategory === 'cumpara' ? (
                        <>
                            {
                                numarApartamente.length > 0 && (
                                    <Link to={`/proprietati/1`} className="noStyleLink">
                                        <CategoryCard image={ApartamenteImg} title='Apartamente' amount={numarApartamente.length} />
                                    </Link>
                                )
                            }
                            {
                                numarCase.length > 0 && (
                                    <Link to={`/proprietati/2`} className="noStyleLink">
                                        <CategoryCard image={Case} title='Case' amount={numarCase.length} />
                                    </Link>
                                )
                            }
                            {
                                numarTerenuri.length > 0 && (
                                    <Link to={`/proprietati/4`} className="noStyleLink">
                                        <CategoryCard image={Terenuri} title='Terenuri' amount={numarTerenuri.length} />
                                    </Link>
                                )
                            }
                            {
                                numarApartamenteChirie.length > 0 && (
                                    <Link to={`/proprietati/1`} className="noStyleLink">
                                        <CategoryCard image={AnsambluriRez} title='Ansambluri Rezidentiale' amount='10' />
                                    </Link>
                                )
                            }
                            {
                                numarSpatiiComerciale.length > 0 && (
                                    <Link to={`/proprietati/3`} className="noStyleLink">
                                        <CategoryCard image={Commercial} title='Spatii Comerciale' amount={numarSpatiiComerciale.length} />
                                    </Link>
                                )
                            }
                            {
                                numarSpatiiIndustriale.length > 0 && (
                                    <Link to={`/proprietati/5`} className="noStyleLink">
                                        <CategoryCard image={Industrial} title='Spatii Industriale' amount={numarSpatiiIndustriale.length} />
                                    </Link>
                                )
                            }
                            {
                                numarSpatiiBirouri.length > 0 && (
                                    <Link to={`/proprietati/6`} className="noStyleLink">
                                        <CategoryCard image={Spatii} title='Spatii Birouri' amount={numarSpatiiBirouri.length} />
                                    </Link>
                                )
                            }
                            {
                                numarHoteluri.length > 0 && (
                                    <Link to={`/proprietati/8`} className="noStyleLink">
                                        <CategoryCard image={Hotel} title='Hoteluri / Pensiuni' amount={numarHoteluri.length} />
                                    </Link>
                                )
                            }

                        </>
                    ) : (
                        <>
                            {numarApartamenteChirie.length > 0 && (
                                <Link to={`/inchiriaza/1`} className="noStyleLink">
                                    <CategoryCard image={ApartamenteImg} title='Apartamente' amount={numarApartamenteChirie.length} />
                                </Link>
                            )}
                            {numarCaseChirie.length > 0 && (
                                <Link to={`/inchiriaza/2`} className="noStyleLink">
                                    <CategoryCard image={Case} title='Case' amount={numarCaseChirie.length} />
                                </Link>
                            )}
                            {numarTerenuriChirie.length > 0 && (
                                <Link to={`/inchiriaza/4`} className="noStyleLink">
                                    <CategoryCard image={Terenuri} title='Terenuri' amount={numarTerenuriChirie.length} />
                                </Link>
                            )}
                            {
                                numarSpatiiComercialeChirie.length > 0 && (
                                    <Link to={`/inchiriaza/3`} className="noStyleLink">
                                        <CategoryCard image={Commercial} title='Spatii Comerciale' amount={numarSpatiiComercialeChirie.length} />
                                    </Link>
                                )
                            }
                            {
                                numarSpatiiIndustrialeChirie.length > 0 && (
                                    <Link to={`/inchiriaza/5`} className="noStyleLink">
                                        <CategoryCard image={Industrial} title='Spatii Industriale' amount={numarSpatiiIndustrialeChirie.length} />
                                    </Link>
                                )
                            }
                            {
                                numarSpatiiBirouriChirie.length > 0 && (
                                    <Link to={`/inchiriaza/6`} className="noStyleLink">
                                        <CategoryCard image={Spatii} title='Spatii Birouri' amount={numarSpatiiBirouriChirie.length} />
                                    </Link>
                                )
                            }
                            {
                                numarHoteluriChirie.length > 0 && (
                                    <Link to={`/inchiriaza/8`} className="noStyleLink">
                                        <CategoryCard image={Hotel} title='Hoteluri / Pensiuni' amount={numarHoteluriChirie.length} />
                                    </Link>
                                )
                            }
                        </>
                    )}

            </div>
            <HomeSeparator>
                <span className='display-text'>
                    IMPREUNA<br />
                    <span className='lowPriority'>gasim</span> CASA<br />
                    PERFECTA!
                </span>
            </HomeSeparator>
            <Heading2 text='Cele mai Recente Oferte' />
            <div className='homePropertyGridContainer'>
                {apartamente}
            </div>
        </div>
    );
}

export default HomePage;