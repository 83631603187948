// src/contexts/WishlistContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the context
const WishlistContext = createContext();

// Create a custom hook to access the WishlistContext
export const useWishlist = () => useContext(WishlistContext);

export const WishlistProvider = ({ children }) => {
    const [wishlist, setWishlist] = useState([]);

    // Toggle wishlist function that accepts only the property ID
    const toggleWishlist = (propertyId) => {
        setWishlist((prevWishlist) => {
            const exists = prevWishlist.includes(propertyId); // Check if the ID exists in the wishlist

            if (exists) {
                // Remove from wishlist if it already exists
                return prevWishlist.filter(id => id !== propertyId);
            } else {
                // Add to wishlist if it doesn't exist
                return [...prevWishlist, propertyId];
            }
        });
    };

    // Check if a property ID is in the wishlist
    const isInWishlist = (propertyId) => wishlist.includes(propertyId);

    return (
        <WishlistContext.Provider value={{ wishlist, toggleWishlist, isInWishlist }}>
            {children}
        </WishlistContext.Provider>
    );
};
