
import ImageGallery from 'react-image-gallery'; // Import ImageGallery
import "react-image-gallery/styles/css/image-gallery.css"; // Import the gallery styles
import Heading2 from "../Components/Heading2";
import './PagesCss/productPage.css';
import LocationProductPage from "../Components/LocationProductPage";
import Call from '../images/icons/call.svg';
import SecondaryBtn from "../Components/SecondaryBtn";
import TourIcn from '../images/icons/virtualTour.svg';
import Map from '../images/icons/harta.svg';
import RealEstateProps from "../Components/RealEstateProps";
import Dormitor from '../images/icons/dormitor.svg';
import Baie from '../images/icons/baie.svg';
import Suprafata from '../images/icons/suprafata.svg';
import Garaj from '../images/icons/Garaj.svg';
import TransType from '../images/icons/Keys.svg';
import Floors from '../images/icons/floors.svg';
import Wall from '../images/icons/wall.svg';
import Property from "../Components/Property";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import AgentCard from "../Components/AgentCard";
import { usePhone } from "../Context/PhoneContext";  // Import the PhoneContext hook
import { useWishlist } from "../Context/WishlistContext"; // Import wishlist context
import Heart from '../images/icons/favoriteDetail.svg'; // Import heart icon
import filledHeart from '../images/icons/filledFavorite.svg';
import RealEstateTable from "../Components/RealEstateTable"; // Import filled heart icon for when it's in the wishlist

// Custom arrow component
// Custom left arrow component
const LeftNav = ({ onClick }) => (
    <button className="image-gallery-left-nav galleryBtn" onClick={onClick}>
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"
                  d="M13.25 8.75L9.75 12L13.25 15.25"></path>
        </svg>
    </button>
);

// Custom right arrow component
const RightNav = ({ onClick }) => (
    <button className="image-gallery-right-nav galleryBtn" onClick={onClick}>
        <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"
                  d="M10.75 8.75L14.25 12L10.75 15.25"></path>
        </svg>
    </button>
);


function ProductDetail() {
    const {productId} = useParams();
    const [propertyData, setPropertyData] = useState([]);
    const [image, setImage] = useState([]);
    const [agentHidden, setAgentHidden] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const {setPhoneNumber} = usePhone(); // Use the setPhoneNumber function from the context
    const { toggleWishlist, isInWishlist } = useWishlist(); // Wishlist functionality
    const [isInWishlistState, setIsInWishlistState] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleImageClick = () => {
        if (galleryRef.current) {
            galleryRef.current.fullScreen();
            setIsFullscreen(true);
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

// Fullscreen reference for gallery
    const galleryRef = useRef(null);

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    };
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    };



    const toggleAgentVisibility = () => {
        setAgentHidden(!agentHidden);
    };


    const handleCloseAgentCard = () => {
        setAgentHidden(true);
    };

    const handleClickOutside = (event) => {
        const agentContainer = document.querySelector('.agentContainer');
        const agentContent = document.querySelector('.agentContainer .content');
        if (agentContainer && agentContent) {
            if (agentContainer.contains(event.target) && !agentContent.contains(event.target)) {
                setAgentHidden(true);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://project1.lbi.studio/api/api/Properties.php?property_id=' + productId, { timeout: 10000 });
                const imageResponse = await axios.get('https://project1.lbi.studio/api/api/Images.php?allImages&property_id=' + productId, { timeout: 10000 });

                setPropertyData(response.data.data);
                setPhoneNumber(response.data.data.phone_no);
                const inWishlist = isInWishlist(productId);
                setIsInWishlistState(inWishlist);

                if (imageResponse.data.length === 0) {
                    setImage([]);
                } else {
                    const sortedImages = imageResponse.data.sort((a, b) => a.main_image - b.main_image);
                    setImage(sortedImages.map((item) => ({
                        original: item.image_path, // `original` field is required by react-image-gallery
                        thumbnail: item.image_path, // `thumbnail` field is required
                        description: ''
                    })));
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    console.error('Axios request error:', error.code, error.message);
                    if (error.code === 'ERR_NETWORK') {
                        console.error('Network error occurred. Please check your internet connection.');
                    } else if (error.response && error.response.status === 404) {
                        console.error('Property with ID', productId, 'not found on the server.');
                    } else {
                        console.error('Unexpected Axios error:', error);
                    }
                } else {
                    console.error('Error fetching data:', error);
                }
            }
        };
        fetchData();
    }, [productId]);

    const preprocessDescription = (description, isExpanded) => {
        if (!description) return '';

        // Replace HTML entities
        let cleanedDescription = description.replace(/nbsp;/g, ' ')
            .replace(/#537;/g, 'ș')
            .replace(/#351;/g, 'ș')
            .replace(/#259;/g, 'ă')
            .replace(/#539;/g, 'ț')
            .replace(/#355;/g, 'ț')
            .replace(/acirc;/g, 'â')
            .replace(/icirc;/g, 'î');

        // Truncate text if not expanded
        if (!isExpanded) {
            const maxLength = 450; // Define your max length here
            if (cleanedDescription.length > maxLength) {
                cleanedDescription = cleanedDescription.slice(0, maxLength);
                // Find the last space to truncate at a word boundary
                const lastSpaceIndex = cleanedDescription.lastIndexOf(' ');
                cleanedDescription = cleanedDescription.slice(0, lastSpaceIndex);
            }
        }

        // Split by '.', '!', and '- ' (dash followed by space)
        const parts = cleanedDescription.split(/(\.(?!\d{2})|!+|- )/g);
        const result = [];
        let appendDash = false;

        for (let i = 0; i < parts.length; i++) {
            if (i % 2 === 0) {
                const delimiter = parts[i + 1] || '';
                if (delimiter === '- ') {
                    if (appendDash) {
                        result.push(
                            <div key={i} className='descriptionRow'>
                                - {parts[i].trim()}
                            </div>
                        );
                    } else {
                        result.push(
                            <div key={i} className='descriptionRow'>
                                {parts[i].trim()}
                            </div>
                        );
                        appendDash = true;
                    }
                } else if (/!+/.test(delimiter)) {
                    result.push(
                        <div key={i} className='descriptionRow'>
                            {parts[i].trim() + delimiter}
                        </div>
                    );
                    appendDash = false;
                } else {
                    if (appendDash) {
                        result.push(
                            <div key={i} className='descriptionRow'>
                                - {parts[i].trim() + delimiter}
                            </div>
                        );
                        appendDash = false;
                    } else {
                        result.push(
                            <div key={i} className='descriptionRow'>
                                {parts[i].trim() + delimiter}
                            </div>
                        );
                    }
                    appendDash = false;
                }
            }
        }
        return result;
    };

    const handleWishlistToggle = () => {
        toggleWishlist(productId);
        setIsInWishlistState(!isInWishlistState); // Update the state to reflect the change
    };

    const description = preprocessDescription(propertyData.description || "", isExpanded);


    const handleReadMoreToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='padding-Top '>
            <div style={{padding: "0px"}} className='ImageGallery'>
                {image.length > 0 && (
                    <ImageGallery
                        items={image}
                        showThumbnails={!isFullscreen}
                        showFullscreenButton={true}
                        useBrowserFullscreen={true}
                        showPlayButton={false}
                        thumbnailPosition="bottom"
                        thumbnailHeight='500px'
                        thumbnailWidth='150px'
                        showNav={true}
                        renderLeftNav={(onClick, disabled) => <LeftNav onClick={onClick} disabled={disabled} />} // Custom left arrow
                        renderRightNav={(onClick, disabled) => <RightNav onClick={onClick} disabled={disabled} />} // Custom right arrow
                        slideInterval={5000}
                        showBullets={false}
                        autoPlay={true}
                        ref={galleryRef} // Reference to the gallery component
                        onClick={handleImageClick} // Trigger fullscreen on image click
                        disableThumbnailScroll={false}
                    />
                )}
            </div>
            <div className='containerProductPage'>
                <div className="productDetail">
                    <h2 className='PropertyName'>{propertyData.property_name}</h2>
                    <div onClick={handleWishlistToggle} className='linkPointer largeHeart'>
                        <img src={isInWishlistState ? filledHeart : Heart} alt="Wishlist Icon"/>
                        <span className="wishlist-text">
                            {isInWishlistState ? ' Sterge din Wishlist' : ' Adauga in Wishlist'}
                        </span>
                    </div>
                </div>
                <div>
                    <h2 className='price'>{new Intl.NumberFormat('de-DE').format(propertyData.price)} €</h2>
                </div>
            </div>
            <div className='containerNarrow'>
                <div>
                    <LocationProductPage>{propertyData.zone_name}</LocationProductPage>
                </div>
                <div>
                <SecondaryBtn src={Call} alt='Suna acum' Class='linkPointer mainBtn'
                                  onClick={toggleAgentVisibility}>Suna acum</SecondaryBtn>
                </div>
            </div>
            <div className='containerNarrow'>
                <div>
                    {/* <SecondaryBtn Class='spacing' src={TourIcn} alt='Tur virtual'>vezi tur virual</SecondaryBtn> */}
                    {/* <SecondaryBtn Class='spacing' src={Map} alt='Harta'>vezi pe harta</SecondaryBtn> */}
                </div>
            </div>
            <div className='flexColumnNarrow'>
                <AgentCard
                    className={agentHidden ? 'agentHidden agentContainer' : 'agentContainer'}
                    src={propertyData.ag_photo}
                    agent_surname={propertyData.agent_surname}
                    agent_name={propertyData.agent_name}
                    phone_no={propertyData.phone_no}
                    email={propertyData.email}
                    propertyId={productId}
                    onClose={handleCloseAgentCard} // Pass the close handler
                />
                <h4>ID Proprietate: {propertyData.property_id}</h4>
            <RealEstateTable
                property_id={propertyData.property_id}
                property_type={propertyData.property_type}
                floor={propertyData.floor}
                total_floors={propertyData.total_floors}
                year_constr={propertyData.year_constr}
                use_sqm={propertyData.use_sqm}
                rooms={propertyData.rooms}
                baths={propertyData.baths}
                total_sqm={propertyData.total_sqm}
                balconies={propertyData.balconies}
                orientation={propertyData.orientation}
                parking_spaces={propertyData.parking_spaces}
            />

            </div>
            <div className='flexColumnNarrow'>
                <Heading2 text='Descriere'/>
                <div>
                    {description}
                    {propertyData.description && !isExpanded && (
                        <a href="#!" onClick={handleReadMoreToggle} className="read-more-toggle">
                            Vezi mai mult
                        </a>
                    )}
                    {propertyData.description && isExpanded && (
                        <a href="#!" onClick={handleReadMoreToggle} className="read-more-toggle">
                            Vezi mai putin
                        </a>
                    )}
                </div>
            </div>
            <div className='flexColumnNarrow'>
                <Heading2 text='Mai multe detalii'/>
                <div className='RealEstatePropsWrapperFullWidth'>
                    {
                        propertyData.rooms && propertyData.rooms !== '' && propertyData.rooms !== ' ' && (
                            <RealEstateProps heading='Dormitoare' icon={Dormitor} amount={propertyData.rooms}/>
                        )
                    }
                    {
                        propertyData.baths && propertyData.baths !== '' && propertyData.baths !== " " && (
                            <RealEstateProps heading='Bai' icon={Baie} amount={propertyData.baths}/>
                        )
                    }
                    {
                        propertyData.use_sqm && propertyData.use_sqm !== '' && propertyData.use_sqm !== ' ' && (
                            <RealEstateProps heading='Suprafata Utila' icon={Suprafata} amount={propertyData.use_sqm}
                                             type='mp'/>
                        )
                    }
                    {
                        propertyData.total_sqm && propertyData.total_sqm !== '' && propertyData.total_sqm !== ' ' && (
                            <RealEstateProps heading='Suprafata Totala' icon={Suprafata} amount={propertyData.total_sqm}
                                             type='mp'/>
                        )
                    }
                    {
                        propertyData.parking_spaces && propertyData.parking_spaces !== '' && propertyData.parking_spaces !== ' ' && (
                            <RealEstateProps heading='Locuri de Parcare' icon={Garaj} amount={propertyData.parking_spaces}/>
                        )
                    }
                    {
                        propertyData.year_constr && propertyData.year_constr !== '' && propertyData.year_constr !== ' ' && (
                            <RealEstateProps heading='An constructie' icon={Wall} amount={propertyData.year_constr}/>
                        )
                    }
                    {
                        typeof propertyData.trans_id === 'number' && (
                            <RealEstateProps
                                heading='Tip tranzactie'
                                icon={TransType}
                                amount={
                                    propertyData.trans_id === 14 ? 'Inchiriere' :
                                        propertyData.trans_id === 16 ? 'Vanzare' :
                                            'Vanzare' // Fallback value if trans_id is neither 14 nor 16
                                }
                            />
                        )
                    }
                    {
                        propertyData.total_floors && propertyData.total_floors !== '' && propertyData.total_floors !== ' ' && (
                            <RealEstateProps heading='Numar etaje' icon={Floors} amount={propertyData.total_floors}/>
                        )
                    }

                </div>
            </div>
            {/*<div className='flexColumnNarrow'>*/}
            {/*    <Heading2 text='Proprietati similare'/>*/}
            {/*    <div className='realEstateListing'>*/}
            {/*        <Property image='/images/properties/house2.png' price='350.000' location='Selimbar, Pictor Brana, Zona Shopping City' />*/}
            {/*        <Property image='/images/properties/house1.png' price='100.000' location='Sibiu, Strada aviatorilor' />*/}
            {/*        <Property image='/images/properties/house1.png' price='350.000' location='Selimbar, Pictor Brana, Zona Shopping City' />*/}
            {/*        <Property image='/images/properties/house1.png' price='350.000' location='Selimbar, Pictor Brana, Zona Shopping City' />*/}
            {/*        <Property image='/images/properties/house1.png' price='350.000' location='Selimbar, Pictor Brana, Zona Shopping City' />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default ProductDetail;