import React from 'react';

function TermsAndConditions() {
    return (
        <div style={{ padding: '20px' }}>
            <h1>Termeni și condiții</h1>

            <p>
                Bine ați venit pe site-ul <strong>atumimobiliare.ro</strong>! Accesul și utilizarea acestui site sunt supuse
                următoarelor termeni și condiții. Vă rugăm să citiți cu atenție aceste condiții înainte de a continua navigarea
                pe site. Utilizarea acestui site implică acceptarea în totalitate a termenilor și condițiilor enumerate mai jos.
            </p>

            <h2>1. Informații generale</h2>
            <p>
                <strong>atumimobiliare.ro</strong> este un site web de prezentare al serviciilor oferite de compania noastră.
                Ne rezervăm dreptul de a modifica sau actualiza în orice moment conținutul site-ului fără o notificare prealabilă.
            </p>

            <h2>2. Proprietatea intelectuală</h2>
            <p>
                Toate materialele publicate pe acest site, inclusiv dar fără a se limita la articole, imagini, grafică și
                conținut audio-video, sunt protejate de drepturile de autor și de alte drepturi de proprietate intelectuală.
                Acestea nu pot fi copiate, distribuite, modificate sau republicate fără permisiunea expresă a proprietarului
                drepturilor.
            </p>

            <h2>3. Utilizarea site-ului</h2>
            <p>
                Sunteți de acord să utilizați acest site în conformitate cu toate legile aplicabile și să nu desfășurați activități
                care ar putea aduce prejudicii site-ului, utilizatorilor săi sau terților. Este interzis să utilizați site-ul pentru
                a încărca, distribui sau transmite materiale ilegale, dăunătoare, amenințătoare sau obscene.
            </p>

            <h2>4. Limitarea răspunderii</h2>
            <p>
                <strong>atumimobiliare.ro</strong> nu garantează acuratețea sau completitudinea informațiilor disponibile pe
                site și nu poate fi considerată răspunzătoare pentru orice daune directe sau indirecte care pot rezulta din
                utilizarea informațiilor de pe acest site.
            </p>

            <h2>5. Politica de confidențialitate</h2>
            <p>
                Protejarea datelor dumneavoastră personale este importantă pentru noi. Pentru mai multe informații despre modul
                în care colectăm, utilizăm și protejăm datele personale, vă rugăm să consultați politica noastră de
                confidențialitate.
            </p>

            <h2>6. Modificări ale termenilor și condițiilor</h2>
            <p>
                Ne rezervăm dreptul de a modifica acești termeni și condiții în orice moment. Orice modificări vor fi afișate pe
                această pagină și vor intra în vigoare imediat după publicare. Vă recomandăm să verificați periodic această
                secțiune pentru a fi la curent cu eventualele schimbări.
            </p>

            <h2>7. Contact</h2>
            <p>
                Dacă aveți întrebări sau nelămuriri legate de acești termeni și condiții, ne puteți contacta prin intermediul
                formularului de contact disponibil pe site sau la adresa de email <strong>contact@atumimobiliare.ro</strong>.
            </p>
        </div>
    );
};

export default TermsAndConditions;
